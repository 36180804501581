import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import ReactPlayer from 'react-player'

const useStyles = makeStyles({
    root: {
        backgroundColor: '#FFFFFF',
        position: 'absolute',
        top: 0,
        height: 4,
        width: 300
    },
});

const Player = ({audioFile, play}) => {
    const classes = useStyles();
    const [progress, setProgress] = React.useState(0);

    // React.useEffect(() => {
    //     const timer = setInterval(() => {
    //         setProgress((oldProgress) => {
    //             if (oldProgress === 100) {
    //                 return 0;
    //             }
    //             const diff = Math.random() * 10;
    //             return Math.min(oldProgress + diff, 100);
    //         });
    //     }, 500);
    //
    //     return () => {
    //         clearInterval(timer);
    //     };
    // }, []);
    return (
        <div className={classes.root}>
            <LinearProgress variant="determinate" value={progress} />
            <ReactPlayer forceAudio url={audioFile} playing={play} loop width={0} height={0} onProgress={ progress => setProgress(progress.played * 100)}/>
        </div>
    );
};

export default Player;
