import React from 'react';
import Cards from './components/cards'
import {useParams} from "react-router-dom";
import CardPreview from "./components/CardPreview";
import { Base64 } from 'js-base64';

const ShowCard = () => {
    let { audioFile, slug, cardHash } = useParams();

    let paramAudioFile = false;
    let paramCardHash = false;
    let paramCard = false;

    const isValidAudio = (audioFile) =>{
        const domainRegex = /drmias|heartbuds/i
        return !!Base64.decode(audioFile).match(domainRegex);
    }

    const audioBase64 = (audioFile) => {
        if(isValidAudio(audioFile)) return Base64.decode(audioFile);
        return false
    }

    const createCardHash = () => {
        const card = Cards.find( c => c.slug === slug )
        return Base64.encode(JSON.stringify({ card, audioFile }));
    }

    const cardUnhash = (cardHash) => {
        const cardJson = Base64.decode(cardHash);
        return JSON.parse(cardJson);
    }

    console.log("slug && audioFile", slug, audioFile);
    if(slug && audioFile){
        if(isValidAudio(audioFile)){
            paramAudioFile = audioBase64(audioFile);
            paramCard = Cards.find( c => c.slug === slug );
            paramCardHash = createCardHash();
            console.log("cardHash", paramCardHash)
        }
    } else if (cardHash){
        const cardObj = cardUnhash(cardHash)
        paramAudioFile = cardObj.audioFile;
        console.log(cardObj)
        paramCard = Cards.find( c => c.slug === cardObj.card.slug );
        paramCardHash = false;
    } else {
        return <div>You Entered an invalid Card</div>
    }

    return (
        <div>
            <CardPreview card={paramCard} cardHash={paramCardHash} audioFile={paramAudioFile}/>
        </div>
    );
};

export default ShowCard;
