import React from 'react';
import ReactDOM from 'react-dom';
import {
    Router,
    Switch,
    Route
} from "react-router-dom";

import { createBrowserHistory } from "history";

import './index.css';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';

import CardSelection from "./pages/CardSelection";
import ShowCard from "./pages/ShowCard";
import Home from "./pages/Home";

const history = createBrowserHistory();

const theme = createMuiTheme({
    root:{outline: 'none'},
    palette: {
        primary: {
            main: '#9f0344',
        },
        secondary: {
            main: '#9f0344',
        },
    },
});

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <Router history={history}>
                <Switch>
                    <Route exact path="/">
                        <Home />
                    </Route>
                    <Route path="/audioFile/:audioFile">
                        <CardSelection />
                    </Route>
                    <Route path="/card/:cardHash">
                        <ShowCard />
                    </Route>
                </Switch>
            </Router>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
