import fathersDay from './assets/fathersday.jpeg'
import fathersDayThumb from './assets/fathersdayThumb.jpeg'
import baby from './assets/baby.png'
import dad from './assets/dad.png'
import grandparent from './assets/grandparent.png'
import love from './assets/love.png'

export default [
    {
        slug: 'fathers-day',
        type: 'image',
        thumb: fathersDay,
        image: fathersDayThumb,
        message: 'Hey! Listen, listen! I have a secret for you! Happy father\'s day.',
        title: 'Father\'s day',
        soundDelay: 2
    },
    {
        slug: 'baby',
        type: 'image',
        thumb: baby,
        image: baby,
        message: 'I\'m already born! Listen to my heart.',
        title: 'Baby Born',
        soundDelay: 2
    },
    {
        slug: 'love',
        type: 'image',
        thumb: love,
        image: love,
        message: '',
        title: 'Love',
        soundDelay: 2
    },
    {
        slug: 'dad',
        type: 'image',
        thumb: dad,
        image: dad,
        message: '',
        title: 'Dad',
        soundDelay: 2
    },
    {
        slug: 'grandparent',
        type: 'image',
        thumb: grandparent,
        image: grandparent,
        message: '',
        title: 'Grandparent',
        soundDelay: 2
    }
]
