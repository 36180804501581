import React, {useEffect, useState} from 'react';
import { Container, Typography } from '@material-ui/core'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import {
    EmailShareButton,
    EmailIcon,
    TelegramShareButton,
    TelegramIcon,
    WhatsappShareButton,
    WhatsappIcon
} from "react-share";
import Player from "./Player";
import Cards from "./cards";
import {Base64} from "js-base64";

const overlayStyle = {
    position: 'fixed', /* Sit on top of the page content *//* Hidden by default */
    width: '100%', /* Full width (cover the whole page) */
    height: '100%', /* Full height (cover the whole page) */
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.01)', /* Black background with opacity */
    zIndex: 2, /* Specify a stack order in case you're using a different order for other elements */
    cursor: 'pointer' /* Add a pointer on hover */
}
const overlayButton ={
    position: 'absolute',
    top: '50%',
    left: '50%',
    fontSize: 50,
    color: 'white',
    transform: 'translate(-50%,-50%)',
    maxHeight: '100%',
    maxWidth: '100%'
}


const CardPreview = ({card, share, audioFile, modal}) => {
    const [play, setPlay] = useState(true)

    const createCardHash = (card) => {
        const newCard = Cards.find( c => c.slug === card.slug )
        return Base64.encode(JSON.stringify({ card: newCard, audioFile }));
    }

    useEffect(() => {
        if(card) console.log(createCardHash(card))
    }, [])

    return (
        <Container fixed style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', minHeight: 500, maxHeight: '80%' }}>
            { !play && !modal && <div style={overlayStyle}><div  onClick={() => setPlay(!play)}  style={overlayButton}><PlayCircleFilledIcon color='primary' style={{fontSize: 100}}/></div></div>}
            { (play || modal) && <>
            <Player play={play} audioFile={audioFile}/>
            { card.type === 'image' &&
                <div>
                    <img onClick={() => setPlay(!play)}  style={{display: 'flex',maxWidth: '100%',maxHeight: '100%', overflow: 'auto', marginTop: 20}} alt='Card' src={card.image} />
                </div>
            }
            { share && <div>
                <EmailShareButton >
                    <EmailIcon/>
                </EmailShareButton>
                <TelegramShareButton>
                    <TelegramIcon/>
                </TelegramShareButton>
                <WhatsappShareButton>
                    <WhatsappIcon/>
                </WhatsappShareButton>
            </div> }
            </> }
        </Container>

    );
};

export default CardPreview;
