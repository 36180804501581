import React from 'react';
import {Card as MCard, CardActionArea, CardActions, CardContent, CardMedia, Button, Typography, Modal } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import CardPreview from "./CardPreview";

const useStyles = makeStyles({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 240,
    },
});

const Card = ({card, audioFile}) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
        <MCard>
            <CardActionArea onClick={handleOpen}>
                <CardMedia
                    className={classes.media}
                    image={card.thumb}
                    title="Contemplative Reptile"
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {card.title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        {card.message}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions>
                <Button fullWidth variant="contained" color="primary" onClick={handleOpen}>
                    Preview
                </Button>
            </CardActions>
        </MCard>
            <Modal
                style={{outline: 'none'}}
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                { CardPreview({card, share: true, audioFile, modal: true}) }
            </Modal>
        </>
    );
};

export default Card;
