import React from 'react';
import { useParams } from "react-router-dom";
import { Base64 } from 'js-base64';
import { Container, Grid, Typography } from '@material-ui/core'
import Cards from './components/cards'
import Card from "./components/Card";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 240,
    },
    header: {
        margin: 20
    }
});
const CardSelection = () => {
    const classes = useStyles();
    let { audioFile } = useParams();
    console.log(Base64.decode(audioFile))
    return (
        <Container fixed>
            <div className={classes.header}>
                <Typography variant="h4" component="h4">Choose you card:</Typography>
                <Typography>Select the card of your preference and share with your friends.</Typography>
            </div>

            <Grid container spacing={3}>
                { Cards.map( c => <Grid item xs={12} sm={6} md={3} key={Math.random()} ><Card card={c} audioFile={Base64.decode(audioFile)} /></Grid>) }
            </Grid>
        </Container>
    );
};

export default CardSelection;
